.ModalWelcome .WelcomeMessage
{
    display: flex;
    justify-content: center;
}

.ModalWelcome .Audits
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalWelcome .KYC
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalWelcome .Image
{
    height: 75px;
}