.VaultFilter
{
    display: grid;
    gap: 5px;
    grid-template-columns: 190px;
    grid-template-areas:                
        "name asset deposited sortOrder"
        "platform type zeroBalance .";
}

.VaultFilter>.Group
{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: 190px;
}

.VaultFilter .Input
{
    width: 100%;
}

.VaultFilter .Select
{
    width: 100%;
}

.VaultFilter .name
{
    grid-area: name;    
}

.VaultFilter .deposited
{
    grid-area: deposited;
}

.VaultFilter .zeroBalance
{
    grid-area: zeroBalance;
}

.VaultFilter .platform
{
    grid-area: platform;
}

.VaultFilter .type
{
    grid-area: type;
}

.VaultFilter .sortOrder
{
    grid-area: sortOrder;
}

.VaultFilter .asset
{
    grid-area: asset;
}
/*==================================
MOBILE tweaks
==================================*/
@media (max-width: 800px)
{
    .VaultFilter
    {
        width: 350px;
        grid-template-columns: 165px;
 
        grid-template-areas:                
            "name type"
            "platform asset"
            "deposited zeroBalance"
            "sortOrder .";
   }

    .VaultFilter > .Group
    {
        width: 165px;
    }

    .VaultFilter > .Group.name
    {
        width: auto;
    }

    /* .VaultFilter > .Group.name > .Text
    {
        align-self: start;
        padding-left: 17px;
    } */
}