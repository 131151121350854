.ProgressBarVaultBreakEven
{
    background: var(--input_background);
    border: var(--input_border_size) solid;
    border-color: var(--input_border_color);
    border-radius: var(--input_radius);
    padding: 0.5rem;
}

.ProgressBarVaultBreakEven .legend
{
    display: flex;
    justify-content: space-around;
}

.ProgressBarVaultBreakEven INPUT
{
    -webkit-appearance: none;
}

.ProgressBarVaultBreakEven .legendContainer
{
    height: 75px;
    width: 30%;
    border: var(--input_border_size) solid;
    border-color: var(--progressBar_breakEven_yellow);
    border-radius: var(--input_radius);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
/*==================================
colors
==================================*/
:root
{
    --progressBar_breakEven_current: #3498db; 
    --progressBar_breakEven_red: rgb(197, 40, 62); 
    --progressBar_breakEven_yellow: #ffae00; 
    --progressBar_breakEven_green: rgb(38, 191, 64); 
}
/*==================================
thumb
==================================*/
.ProgressBarVaultBreakEven INPUT::-webkit-slider-thumb
{
    margin-top: -1.5px;
    width: 5px;
    height: 20px;
    background: var(--progressBar_breakEven_current);
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    -webkit-appearance: none;
}
.ProgressBarVaultBreakEven INPUT::-moz-range-thumb
{
    width: 5px;
    height: 20px;
    background: var(--progressBar_breakEven_current);
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
}

.ProgressBarVaultBreakEven INPUT::-ms-thumb
{
    width: 5px;
    height: 20px;
    background: var(--progressBar_breakEven_current);
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}