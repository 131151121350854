.Page_User
{
    display: flex;
    gap: 25px;
    align-items: center;
    flex-direction: column;
}

.Page_User .Panel
{
    width: var(--base-min-width);
    max-width: var(--base-max-width);
}

.Page_User .noWallet
{
    text-align: center;
}
/*==================================
stats
==================================*/
.Page_User .Panel_Stats .stats
{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Page_User .Panel_Stats .header
{
    text-align: center;
}

.Page_User .Panel_Stats .name
{
    text-align: left;
}

.Page_User .Panel_Stats .value
{
    text-align: right;
}
/*==================================
infos
==================================*/
.Page_User .Panel_Info .header
{
    text-align: center;
}

.Page_User .Panel_Info .infos .name
{
    width: 150px;
}

.Page_User .Panel_Info .infoRow
{
    display: flex;
    align-items: center;  
}

.Page_User .Panel_Info .infoRow .value
{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%
}

.Page_User .Panel_Info .infoRow .Input
{
    width: 100%;
}

.Page_User .Panel_Info .infoRow.Wallet .name
{
    grid-area: wallet_name;
}

.Page_User .Panel_Info .infoRow.Wallet .value
{
    grid-area: wallet_value;
}
/*==================================
change password
==================================*/
.ChangePassword FORM
{
    display: flex;
    flex-direction: column;
}

.ChangePassword .password
{
    display: flex;
    flex-direction: column;
}
/*==================================
voting power
==================================*/
.Page_User .votingPower
{
    display: flex;
    justify-content: flex-end;
}

.Page_User .VotingPowerBreakdown .Description a,
.Page_User .VotingPowerBreakdown .Description a:hover,
.Page_User .VotingPowerBreakdown .Description a:visited 
{
    text-decoration: underline;
    color: var(--secondary);
}

.Page_User .VotingPowerBreakdown .Group.Description
{
    display: block;
    font-style: italic;
    max-width: 250px;
    margin-bottom:15px;
    text-align: left;
    font-size:.9rem;
}

.Page_User .VotingPowerBreakdown > .Group
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Page_User .VotingPowerBreakdown > .Group.TotalPower
{
    border-top: solid 1px white;
}

.Page_User .VotingPowerBreakdown > .Group > .Group:first-child
{
    font-weight: bold;
}

.Page_User .VotingPowerBreakdown > .Group > .Group + .Group
{
    padding-left: 10px;
    text-align: right;
}
/*==================================
MOBILE tweaks
==================================*/
@media (max-width: 600px)
{
    .Page_User .Panel_Info .infoRow.Wallet
    {
        display: grid;
        grid-template-areas:
            "wallet_name"
            "wallet_value";
    }

    .Page_User .Panel_Info .infoRow.Wallet .value
    {
        font-size: .65em;
    }
}