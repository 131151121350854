input[type='range'] {
	width:100%;
}

.clickable {
	cursor: pointer;
}

.ModalContainer {
	color:white;
}