.PagePool
{
    display: flex;
    gap: 25px;
    align-items: center;
    flex-direction: column;
}

.PagePool .info
{
    display: flex;
    gap: 25px;
    flex-direction: column;
}
/*==================================
Pool
==================================*/
.PagePool .Panel
{
    width: var(--base-min-width);
    max-width: var(--base-max-width);
}

.Panel_Pool .header
{
    text-align: center;
}

.Panel_Pool .intro
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Panel_Pool .wrongChain
{
    text-align: center;
    padding-top: 1rem;
}

.Panel_Pool .menu
{
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Panel_Pool .Pool
{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Panel_Pool .Pool .arrow
{
    fill: var(--primary);
    display: flex;
    justify-content: center;
}

.Panel_Pool .Pool .buy
{    
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.Panel_Pool .Pool .sell
{    
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.Panel_Pool .Pool .orderButton
{    
    height: 80px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 25px;
}
/*==================================
stats
==================================*/
.Panel_Pool .stats
{
    padding-top: 10px;
    text-align: center;    
    width: 100%
}

.Panel_Pool .StatsTables .row > div:first-child::after
{
    content: ':';
    font-size: var(--size_small);
    color: var(--secondary);
}

.Panel_Pool .stats .row > div
{
    text-align: left;
}

.Panel_Pool .stats .row > div + div
{
    text-align: right;
}

.Panel_Pool .buttons
{
    display: flex;
    justify-content: space-around;
}
/*==================================
charts
==================================*/
.Panel_Pool .charts
{
    text-align: center;
    width: 100%
}

.Panel_Pool .charts .header
{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/*==================================
MOBILE tweaks
==================================*/
@media (max-width: 600px)
{
    .BuyMoon,
    .SellMoon 
    {
        font-size:.8em;
    }

    .PagePool .InputPoolAmount .Input
	{
		/* TODO: This will need tweaks for longer Pool names. */
		max-width: 40vw;
	}
}