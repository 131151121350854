.VaultView
{
    display: flex;
    background-color: var(--window); 
    border-radius: var(--button_radius);
}

@media (max-width: 850px)
{
    .VaultView
    {
        width: 450px;
        display: grid;
        grid-template-columns: 150px;
        grid-template-areas:
            "header header depositWithdraw"
            "dailyROI userDeposit compound";
    }    
}

.VaultView .VaultInfoGroup
{
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.VaultView .VaultInfoGroup .groupName .Text
{
    display: flex;
}

.VaultView .HelpIcon
{
    display: inline-block;
}

.VaultView .AprBreakdown > .Group
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.VaultView .AprBreakdown > .Group.TotalApr
{
    border-top: solid 1px var(--secondary);
}

.VaultView .AprBreakdown > .Group > .Group:first-child
{
    font-weight: bold;
}

.VaultView .AprBreakdown > .Group > .Group + .Group
{
    padding-left: 10px;
    text-align: right;
}

/*==================================
compoundInfo
==================================*/
.VaultView .compoundInfo
{
    width: 25px;
    height: 25px;
    border: 2px solid black;
    overflow: hidden;
    border-radius: 10px;
}

.VaultView .compoundInfo.green
{
    background: var(--color_green);
}

.VaultView .compoundInfo.yellow
{
    background: var(--color_yellow);
}

.VaultView .compoundInfo.red
{
    background: var(--color_red);
}
/*==================================
grid
==================================*/
@media (max-width: 1000px)
{    
    .VaultView .tvl
    {
        display: none;        
    }
}

@media (max-width: 850px)
{
    .VaultView .VaultHeader
    {
        width: 300px;
        justify-content: center;
    }
}

.VaultView .VaultHeader
{
    grid-area: header;
}

.VaultView .tvl
{
    grid-area: tvl;
}

.VaultView .dailyROI
{
    grid-area: dailyROI;
}

.VaultView .depositWithdraw
{
    grid-area: depositWithdraw;
}

.VaultView .compound
{
    grid-area: compound;
}

.VaultView .userDeposit
{
    grid-area: userDeposit;
}
/*==================================
deposit/withdraw
==================================*/
.VaultView .depositWithdraw
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.VaultView .depositWithdraw BUTTON
{
    width: 140px;
}

.VaultView .depositWithdraw .enable
{
    height: 80px;
}
/*==================================
compound
==================================*/
.VaultView .compound
{
    display: flex;
    justify-content: center;
}

.VaultView .compound .Button
{
    height: 80px;
    width: 140px;
    flex-direction: column;
}
/*==================================
compound
==================================*/
.VaultView .profit
{
	color: var(--color_green);
}

.VaultView .loss
{
	color: var(--color_red);
}
/*==================================
MOBILE tweaks
==================================*/
@media (max-width: 600px)
{
    .VaultView
    {
        min-width: 350px;
        max-width: 98vw;
    }

    .VaultView .VaultInfoGroup
    {
        width: 100px;
    }

    .VaultView .depositWithdraw BUTTON
    {
        width: 100px;
    }   

    .VaultView .compound .Button
    {
        width: 100px;
    }

    .VaultView .VaultHeader
    {
        width: 225px;
    }

    .VaultView .Button
    {
        font-size:.8em;
    }
}
