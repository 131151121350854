/*==================================
variables
==================================*/
:root
{
    --font: Poppins; 
    --primary: #ffae00;
    --secondary: #f5f5f5;
    --colorMenu: #000;
    --grayedOut: #607068;
    --link: #ffae00;
    --linkSecondary: #f5f5f5;
    --linkMenu: var(--link);
    --background: #000000;    
    --scroll: #ffae00;
    --secondary-dark: #757575;
    --stroke: #000;

    --glow-base-r: 255;
    --glow-base-g: 217;
    --glow-base-b: 0;
    --glow-base: rgb(var(--glow-base-r), var(--glow-base-g), var(--glow-base-b));

    --page_background: #000;
    --nav_background: #000; 
    
    --base-min-width: 600px;
    --base-max-width: 95vw;

    --button-height: 35px;
    --button_background: #000;    
    --button_color: var(--primary);
    --button_stroke: 0px 0 0 var(--stroke);
    --button_backgroundPrimary: var(--primary);
    --button_colorPrimary: #000;
    --button_strokePrimary: var(--button_stroke); /*1px 0 0 var(--stroke), 0 1px 0 var(--stroke), -1px 0 0 var(--stroke), 0 -1px 0 var(--stroke);*/
    --button_border_color: #000;
    --button_border_size: 2px;
    --button_radius: 10px;

    --panel: rgb(39, 38, 44);
    --panel_radius: 10px;
    --panel-border_color: var(--primary);
    --panel_border_size: 0px;
    
    --window: rgb(39, 38, 44);
    --window_light: rgb(59, 58, 64);
    --window_radius: 10px;

    --input: rgb(227, 252, 240);
    --input_background: #000;
    --input_border_color: #000;
    --input_border_size: 2px;
    --input_radius: 10px;

    --size_superSmall: 0.6rem;
    --size_verySmall: 0.7rem;
    --size_small: 0.8rem;
    --size_normal: 1.0rem;
    --size_big: 1.5rem;
    --size_veryBig: 3rem;
    --size_menuActive: 1.2rem;

    --weight_light: normal; 
    --weight_normal: bold;
    --weight_bold: bolder;

    --color_green: rgb(38, 191, 64);
    --color_red: rgb(197, 40, 62);    
    --color_yellow: #ffae00;

    --evenOdd: rgba(200, 200, 200, 0.1);
}

@media screen and (max-width: 600px) {
    :root {
        --base-min-width: 350px;
        --base-max-width: 95vw;
    }
}
