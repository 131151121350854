.Page_Vaults
{
    display: flex;
    gap: 25px;
    align-items: center;
    flex-direction: column;
}

.Page_Vaults .Panel
{
    min-width: 450px;
}

.Page_Vaults .noVaults
{
    text-align: center;
}

/*==================================
MOBILE tweaks
==================================*/
@media (max-width: 600px)
{
    .Page_Vaults .Panel
    {
        min-width: 350px;
    }
}