.PageHome
{
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.PageHome .Panel_Info
{
    width: var(--base-min-width);
    max-width: var(--base-max-width);
    text-align: center;
}

.Panel_Info .logo
{
    width: 500px;
    height: 333px;
}
/*==================================
links
==================================*/
.Panel_Info .links
{
    text-align: center;
}

.Panel_Info .links .LinkGroup
{
    display: flex;
    justify-content: center;
}

.Panel_Info .links .LinkGroup .Link
{
   display: flex;
   align-items: center;
   padding-left: 10px;
   padding-right: 10px;
}

.Panel_Info .links .LinkGroup .Link .svgLink
{
    padding-left: 5px;
    padding-right: 5px;
}

.PageHome .HelpIcon
{
    display: inline-block;
}

.PageHome .ProfitFeeBreakdown .Description a,
.PageHome .ProfitFeeBreakdown .Description a:hover,
.PageHome .ProfitFeeBreakdown .Description a:visited 
{
    text-decoration: underline;
    color: var(--secondary);
}

.PageHome .ProfitFeeBreakdown .Group.Description
{
    display: block;
    font-style: italic;
    max-width: 250px;
    margin-bottom:15px;
    text-align: left;
    font-size:.9rem;
}

.PageHome .ProfitFeeBreakdown > .Group
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.PageHome .ProfitFeeBreakdown > .Group.TotalFee
{
    border-top: solid 1px white;
}

.PageHome .ProfitFeeBreakdown > .Group > .Group:first-child
{
    font-weight: bold;
}

.PageHome .ProfitFeeBreakdown > .Group > .Group + .Group
{
    padding-left: 10px;
    text-align: right;
}

/* .svgLink.twitter
{
    fill: #239EEE;
}

.svgLink.tg 
{
    fill: #31ABE8;
} */

/*==================================
stats
==================================*/
.Panel_Info .stats
{
    text-align: center;
}

.Panel_Info .stats .StatsTables
{
    display: flex;
    justify-content: space-around;
}

.Panel_Info .stats .StatsTable
{
    width: 250px;
}

.Panel_Info .stats .name
{
    text-align: left;
}

.Panel_Info .stats .value
{
    text-align: right;
}

/*==================================
charts
==================================*/
.PageHome .Panel_Charts
{
    width: var(--base-min-width);
    max-width: var(--base-max-width);
    text-align: center;
}

.Panel_Charts .charts
{
    text-align: center;
    width: 100%
}

.Panel_Charts .charts .header
{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/*==================================
MOBILE tweaks
==================================*/
@media (max-width: 600px)
{
    .Panel_Info .stats .StatsTable
    {
        width: 250px;
    }

    .Panel_Info .logo
    {
        width: 325px;
        height: 200px;
    }
}