.Page_VaultDetails
{
    display: flex;
    gap: 25px;
    align-items: center;
    flex-direction: column;
}

.Page_VaultDetails .Panel_Wait
{
	text-align: center;
}

.Page_VaultDetails .HelpIcon
{
    display: inline-block;
}

.Page_VaultDetails .AprBreakdown
{
}

.Page_VaultDetails .AprBreakdown > .Group
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Page_VaultDetails .AprBreakdown > .Group.TotalApr
{
    border-top: solid 1px var(--secondary);
}

.Page_VaultDetails .AprBreakdown > .Group > .Group:first-child
{
    font-weight: bold;
}

.Page_VaultDetails .AprBreakdown > .Group > .Group + .Group
{
    padding-left: 10px;
    text-align: right;
}

.Page_VaultDetails .Panel_Details
{
	width: var(--base-min-width);
    max-width: var(--base-max-width);
	display: flex;
    flex-wrap: wrap;
}

.Page_VaultDetails .tvl
{
	text-align:right;
	position:relative;
}

.Page_VaultDetails .tvl > .row > div
{
	width:100%;
}

.Page_VaultDetails .middle {
	top:50%;
	transform: translateY(-50%);
}

.Page_VaultDetails .Panel > *:first-child + * {
	border-top: 2px solid red;
	border-image: linear-gradient(to right, transparent, transparent, rgb(244, 179, 48), transparent, transparent) 1 stretch;
}

.Page_VaultDetails .detail-row.header {
	font-weight:bold;
	margin-top:10px;
	text-decoration:underline;
}

.Page_VaultDetails .detail-row > div:first-child {
	text-align:left;
}

.Page_VaultDetails .detail-row > div + div {
	text-align:right;
}

.Page_VaultDetails .Text.heading {
	text-align:left;
	margin-left:0;
	border-bottom: 2px solid red;
	border-image: linear-gradient(to right, rgb(244, 179, 48), transparent, transparent) 1 stretch;
}

.row.detail-row + .Text.heading {
	margin-top:40px;
}

.Page_VaultDetails h2,
.Page_VaultDetails h3 {
	color:rgb(244, 179, 48);
}


.Page_VaultDetails .header {
	position:relative;
	display: flex;
}

.Page_VaultDetails .header .VaultNameGroup {
	display: inline-block;
	margin-left:15px;
	position: relative;
	top: -35px;
}

.Page_VaultDetails .header .VaultName > .middle {
	padding-left:125px;
}

.Page_VaultDetails .header .VaultName > .middle + div {
	position:absolute;
	bottom:15px;
	left:125px;
}

.Page_VaultDetails .Text.heading {
	display:block;
	text-align:center;
}

.Page_VaultDetails div.VaultHeader {
	width: auto;
}