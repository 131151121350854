.ButtonSwapLiquidity
{
    height: 35px;
    display: flex;
    align-items: center;
}

/*==================================
icon
==================================*/
.ButtonSwapLiquidity .TokenIcon
{
    width: 24px;
    height: 24px;
    margin-left: 5px;
    margin-right: 5px;
}

.ButtonSwapLiquidity .TokenIcon.tokenIconSingle .icon
{
	width: 24px;
	height: 24px;
}