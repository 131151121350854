.ModalVaultDeposit .content
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalVaultDeposit .content>.Text
{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ModalVaultDeposit .InputTokenAmount
{
    width: 100%;
}

.ModalVaultDeposit .ProgressBarVaultBreakEven
{
    margin-top: 1rem;
    width: 100%;
}