.PoolHeader
{
    align-items: center;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PoolHeader .PoolHeaderGroup
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.PoolHeader .depositRewardInfo
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}