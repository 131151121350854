.Page_Admin
{
    display: flex;
    gap: 25px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.Page_Admin .header
{
    text-align: center;
}

.Page_Admin .AdminCenter
{
    display: flex;
    flex-direction: column;
}

.Page_Admin .SyncProcessControl
{
    display: flex;
    flex-direction: column;
}

.Page_Admin .SyncProcessPanels
{
    display: flex;
}

.Page_Admin .SyncProcessPanel
{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    width: 200px;
    height: 175px;
    border: 2px solid #fff;
    border-radius: var(--window_radius);
    margin: 5px;
    padding: 5px;
}

.Page_Admin TEXTAREA
{
    height: 250px;
    background: var(--input_background);
    color: var(--input);
    font-weight: bold;
    padding: 10px;
	border-radius: 15px;
	resize: none;
}