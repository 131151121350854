.ModalVaultStrategySettings .settings
{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ModalVaultStrategySettings .settingsRow
{
    display: flex;
    justify-content: center;
}

.ModalVaultStrategySettings .settingsCol
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalVaultStrategySettings .contract
{
    width: 25px;
    height: 25px;
}