.ModalPoolWithdraw .content
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalPoolWithdraw .content>.Text
{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ModalPoolWithdraw .InputTokenAmount
{
    width: 100%;
}