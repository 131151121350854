.PageLaunchpad
{
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
/*==================================
Launchpad
==================================*/
.PageLaunchpad .Panel
{
    width: var(--base-min-width);
    max-width: var(--base-max-width);
}

.Panel_LaunchpadHeader,
.Panel_LaunchpadContent
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Panel_LaunchpadHeader .funded
{
    text-align: center;
    width: 100%;
    margin-top: 25px;
}

.Panel_LaunchpadHeader .ProgressBar
{
    margin-top: 25px;
    width: 95%;
}

.Panel_LaunchpadHeader .header,
.Panel_LaunchpadContent .header
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Panel_LaunchpadContent > .info
{
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.Panel_Launchpad .wrongChain
{
    text-align: center;
    padding-top: 1rem;
}

.Panel_LaunchpadContent .launchpadNotStarted
{
    text-align: center;
    width: 100%;
}

.Panel_LaunchpadContent .launchpadStarted
{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.Panel_LaunchpadContent .launchpadEnded
{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.Panel_LaunchpadContent .choice
{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}

.Panel_LaunchpadContent > .choice > .Button
{
    width: 75%;
}

.Panel_LaunchpadContent .Group .head
{
    width: 100%;
    text-align: center;
    background: #000;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.Panel_LaunchpadContent .claimAndWithdraw,
.Panel_LaunchpadContent .claimOversubscribed
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid;
    border-radius: 25px;
    width: 45%;
    height: 150px;
    margin: 10px;
}

.Panel_LaunchpadContent .claimAndWithdraw > .Button,
.Panel_LaunchpadContent .claimOversubscribed > .Button
{
    width: 80%;
}

.Panel_LaunchpadContent .deposit
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid;
    border-radius: 25px;
    width: 95%;
    height: 200px;
    margin: 10px;
}

.Panel_LaunchpadContent .deposit > .Button
{
    width: 75%;
}
/*==================================
MOBILE tweaks
==================================*/
@media (max-width: 600px)
{
    .PageLaunchpad .choice > .Group
	{
		width: 100%
	}
}