.PoolView
{
    background-color: var(--window); 
    border-radius: var(--button_radius);
    width: 450px;
    display: grid;
    grid-template-columns: 150px;
    grid-template-areas:
        "header header depositWithdraw"
        "userDeposit userPending claim"
        "poolTime currentRemainingReward rewardPerMoon";
}

.PoolView .PoolInfoGroup
{
    width: 150px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PoolView .PoolInfoGroup .groupName .Text
{
    display: flex;
}
/*==================================
grid
==================================*/
.PoolView .PoolHeader
{
    width: 300px;
    height: 80px;
    justify-content: center;
}

.PoolView .PoolHeader
{
    grid-area: header;
}

.PoolView .ttl
{
    grid-area: ttl;
}

.PoolView .tvl
{
    grid-area: tvl;
}

.PoolView .claim
{
    grid-area: claim;
}

.PoolView .poolTime
{
    grid-area: poolTime;
}

.PoolView .totalRewards
{
    grid-area: totalRewards;
}

.PoolView .currentRemainingReward
{
    grid-area: currentRemainingReward;
}

.PoolView .rewardPerMoon
{
    grid-area: rewardPerMoon;
}

.PoolView .userPending
{
    grid-area: userPending;
}

.PoolView .depositWithdraw
{
    grid-area: depositWithdraw;
}

.PoolView .userDeposit
{
    grid-area: userDeposit;
}
/*==================================
deposit/withdraw
==================================*/
.PoolView .depositWithdraw
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PoolView .depositWithdraw BUTTON
{
    width: 140px;
}
/*==================================
claim
==================================*/
.PoolView .claim
{
    display: flex;
    justify-content: center;
}

.PoolView .claim .Button
{
    height: 80px;
    width: 140px;
    flex-direction: column;
}
/*==================================
claim
==================================*/
.PoolView .profit
{
	color: var(--color_green);
}
/*==================================
MOBILE tweaks
==================================*/
@media (max-width: 450px)
{
    .PoolView
    {
        width: 300px;
        grid-template-areas:
        "header header"
        "userDeposit userPending"
        "poolTime rewardPerMoon"
        "depositWithdraw claim";
    }

    .PoolView .currentRemainingReward
    {
        display: none;
        visibility: hidden;
    } 
}
